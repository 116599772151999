.container {
    height: 100%;
    width: 100%;
    background-color: #3b3c40;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-position: 60% 50%;
  }

.middleLogo {
    display:flex;
    height: 160px;
    width: 384px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background: radial-gradient(rgba(0,0,0,.6) 0%, transparent 60%);
    border-radius: 50%;
}

.logo{
    animation-name: animation-logo;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    transform: translateX(0);
    opacity: 1;
    height: 80px;
    width: auto;
    position: absolute;
}
@keyframes animation-logo {
    0% {
        opacity: 0;
        transform: translateX(-18px);
    }
    35% {
        opacity: 0;
        transform: translateX(-18px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


.logo-sub{
    animation-name: animation-logo-sub;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    transform: translateX(0);
    opacity: 1;
    top: 56px;
    display: block;
    width: 200px;
    text-align: center;
    position: absolute;
}
@keyframes animation-logo-sub {
    0% {
        opacity: 0;
        transform: translateX(18px);
    }
    35% {
        opacity: 0;
        transform: translateX(18px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.splash-loading {
    display: block;
    height: 36px;
    width: 36px;
    border-radius: 20px;
    background: transparent;
    opacity: 1;
}
@keyframes animation-logo-loading {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
